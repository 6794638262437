<template>
  <div class="page-accounting">
    <lead-center-navigation />
    <div class="tabs-page container">
      <b-tabs pills>
        <b-tab title="Workflows" @click="$navigateTo('lead-center-workflows')" :active="$route.name === 'lead-center-workflows'">
          <lead-center-workflows />
        </b-tab>
        <b-tab title="Blocks library" @click="$navigateTo('lead-center-worflows-blocks-library')" :active="$route.name === 'lead-center-worflows-blocks-library'">
          <lead-center-blocks-library />
        </b-tab>
        <b-tab title="Scenarios" @click="$navigateTo('lead-center-scenarios')" :active="$route.name === 'lead-center-scenarios'">
          <lead-center-scenarios />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import LeadCenterNavigation from '../components/LeadCenterNavigation.vue'
import LeadCenterWorkflows from '@/agGridV2/components/lead-center-workflows.component.vue'
import LeadCenterBlocksLibrary from '@/agGridV2/components/lead-center-blocks-library.component.vue'
import LeadCenterScenarios from '@/agGridV2/components/lead-center-scenarios.component.vue'

export default {
  components: {
    LeadCenterNavigation,
    LeadCenterWorkflows,
    LeadCenterBlocksLibrary,
    LeadCenterScenarios
  }
}
</script>