<template>
  <div class="blocks-main-container">
    <div v-for="(workflow, index) in groupedScenarios" :key="index" class="workflow-container">
      <h2>{{ workflow.workflow_title }}</h2>
      <div v-for="(scenario, scenarioIndex) in workflow.scenarios" :key="scenarioIndex" class="scenario-container">
        <div class="scenario-content">
          <div class="scenario-blocks">
            <strong>Blocks:</strong> {{ scenario.blocks.map(block => block.title).join(', ') }}
            <div class="scenario-platforms mt-2" v-if="scenario.platforms && scenario.platforms.length > 0">
              <strong>Platforms:</strong> {{ scenario.platforms.join(', ') }}
            </div>
            <div class="scenario-dates mt-2" v-if="scenario.dates && scenario.dates.length > 0 && scenario.dates[0] !== null">
              <strong>Dates:</strong> {{ scenario.dates.join(', ') }}
            </div>
          </div>
          <div class="totals mr-3" v-if="scenario.left">
            Left: {{ scenario.left }}
          </div>
          <b-button variant="primary" @click="runScenario(workflow.workflow_id, scenarioIndex)" :disabled="scenario.run || scenario.loading" :class="['run-button', scenario.run ? 'disabled' : '']">
            <b-icon icon="hourglass" v-if="scenario.loading"></b-icon>
            <b-icon icon="play-fill" v-else></b-icon>
          </b-button>
          <b-button class="ml-1" variant="danger" @click="cancelScenario(workflow.workflow_id, scenarioIndex)" :disabled="!scenario.run" :class="['run-button', scenario.run ? '' : 'disabled']">
            <b-icon icon="x"></b-icon>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import MtApi from '@/agGridV2/helpers/mt-api.helper'

export default {
  name: 'lead-center-scenarios',
  data() {
    return {
      scenarios: [],
      interval: null
    }
  },
  async mounted() {
    this.setViewLoader(true)
    this.scenarios = await MtApi.getScenarios()
    this.setViewLoader(false)
    this.interval = setInterval(async () => {
      this.scenarios = await MtApi.getScenarios()
    }, 60000);
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  computed: {
    groupedScenarios() {
      const grouped = {};
      this.scenarios.forEach(item => {
        if (!grouped[item.workflow_id]) {
          grouped[item.workflow_id] = {
            workflow_id: item.workflow_id,
            workflow_title: item.workflow_title,
            scenarios: []
          };
        }
        grouped[item.workflow_id].scenarios.push(item.scenario);
      });
      return Object.values(grouped);
    }
  },
  methods: {
    ...mapMutations(['setViewLoader']),
    async runScenario(workflow_id, scenario_index) {
      this.$set(this.scenarios[scenario_index].scenario, 'loading', true);
      await MtApi.runScenario({
        workflow_id,
        scenario_index
      })
      this.$set(this.scenarios[scenario_index].scenario, 'loading', false);
      this.$set(this.scenarios[scenario_index].scenario, 'run', true);
    },
    async cancelScenario(workflow_id, scenario_index) {
      this.$set(this.scenarios[scenario_index].scenario, 'loading', true);
      await MtApi.cancelScenario({
        workflow_id,
        scenario_index
      })
      this.$set(this.scenarios[scenario_index].scenario, 'loading', false);
      this.$set(this.scenarios[scenario_index].scenario, 'run', false);
    }
  }
}
</script>

<style lang="scss" scoped>
.blocks-main-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.workflow-container {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: opacity 0.3s ease;
}

.scenario-container {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
}

.scenario-container:last-child {
  margin: 0;
}

.block-container {
  margin-bottom: 10px;
}

button {
  margin-top: 10px;
}

.scenario-header {
  margin-bottom: 10px;
}

.scenario-content {
  display: flex;
  align-items: center;
}

.scenario-blocks {
  flex: 1;
  margin-right: 10px;
}

.scenario-block {
  margin-bottom: 5px;
}

.run-button {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.run-button .b-icon {
  font-size: 1rem;
}
</style>
